import "../scss/style.scss";
import "./intersectionobserver.js"; // jQuery内に含めてbundleするとsafariでうまく動かなくなる
// import 'slick-carousel'; // こっちのを読み込むとD-PICSのしくみのslickが崩れるので使用しない
import "./lp-js/slick.min.js"; // HOMEでも使用しているので消さない
// import "./home.js";
// import "./chart.js";
// import "./chartToy.js";
// import "./chartLabel.js";
// import "./roland.regionSelector.js";
import "./lib/jquery.easing.min.js";
// import '@fortawesome/fontawesome-free/js/all';
// import "bootstrap";


jQuery(function ($) {

	// sp 100vh adjustment
	$(function () {
		let vh = document.documentElement.clientHeight * 0.01;
		document.documentElement.style.setProperty('--vh', vh + 'px');
	});

	// smooth scroll
	$('a[href^="#"]').not('.member-list-item').not('.panel-title').click(function () {
		var headerHight = 80;
		var speed = 1000;
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top - headerHight;
		$("html, body").animate({ scrollTop: position }, speed, "easeOutExpo");
		return false;
	});

	// //別調整により必要なくなったのでコメントアウト
	// if( location.hash != '') {
	// 	var headerHight = 80;
	// 	var speed = 1000;
	// 	var target = $(location.hash);
	// 	var position = target.offset().top-headerHight;
	// 	$("html, body").animate({scrollTop:position}, speed, "easeOutExpo");
	// }

	// To top
	var pagetop = $('.toTop');
	pagetop.hide();
	$(window).scroll(function () {

		var scrollHeight, scrollPosition, scrollTop;
		scrollTop = $(window).scrollTop()
		scrollHeight = $(document).height();
		scrollPosition = $(window).height() + scrollTop;

		if (scrollTop > 400 && ((scrollHeight - scrollPosition) > 400)) {
			pagetop.fadeIn(300);
		} else {
			pagetop.fadeOut(300);
		}
	});


	// SP nav
	$('.js-nav-bt').on('click', function () {
		if ($('.js-nav-content').hasClass('close')) {
			$(".js-nav-content").removeClass('close');
			$(".header-box-select").removeClass('close');
			$(this).removeClass('close');
			$(this).children('.js-navTxt').text('MENU');
		} else {
			$(".js-nav-content").addClass('close');
			$(".header-box-select").addClass('close');
			$(this).addClass('close');
			$(this).children('.js-navTxt').text('CLOSE');
		}
	});
	$('.js-nav-bt.close, .nav-linkList__link, .nav-box-linkList__link').on('click', function () {
		$(".js-nav-content, .js-nav-bt.close").removeClass('close');
		$(".js-nav-content, .js-nav-bt.close").removeClass('close');
	});

	// プルダウン
	$('.js-select').on('click', function (event) {
		event.preventDefault();
		$(this).toggleClass('on');
		$(this).next().slideToggle();
	});

	// header change
	//	$(window).scroll(function () {
	//		if($(window).scrollTop() > 100) {
	//			$('.js-change').addClass('change');
	//		} else {
	//			$('.js-change').removeClass('change');
	//		}
	//	});


	// intersectionobserver.jsに変更済
	// $(window).scroll(function () {
	// 	console.log('scroll');
	// 	var scroll = $(window).scrollTop();
	// 	var windowHeight = $(window).height();
	// 	$('.js-fadeUp').each(function () {
	// 		var targetElement = $(this).offset().top;
	// 			if (scroll > targetElement - windowHeight - 10){
	// 				$(this).addClass('scrollin');
	// 		}
	// 		// else {
	// 		// 	$(this).removeClass('scrollin');
	// 		// }
	// 	});
	// });


	// slick for D-PICS LP (System block)
	var panels = $('.block_system .panels');
	panels.slick({
		dots: false,
		prevArrow: false,
		nextArrow: false,
		slidesToShow: 1,
		autoplay: false,
		autoplaySpeed: 5000,
		cssEase: 'ease',
		speed: 1000,
		fade: true,
		responsive: [{
			breakpoint: 767,
			settings: 'unslick',
		}],
	});

	$('.btn_l a, .btn_r a').on('click', function () {
		var tgt = $(this).attr('href');
		var tgtIn = parseInt(tgt.replace('#panel_', ''));
		panels.slick('slickGoTo', tgtIn - 1, false);
		return false;
	});

	$(window).on('resize orientationchange', function () {
		panels.slick('resize');
	});


	// slick for D-PICS LP (Digital yatai block)
	var dpmodal = $('.dp_modal'),
		dpmPanels = $('.dp_modal .panels');
	dpmodal.hide();
	dpmPanels.slick({
		dots: true,
		prevArrow: '<span class="arrow_prev"></span>',
		nextArrow: '<span class="arrow_next"></span>',
		slidesToShow: 1,
		autoplay: false,
		autoplaySpeed: 5000,
		cssEase: 'ease',
		speed: 200,
		fade: true,
	});

	$('.yatai .btn_modal').on('click', function () {
		var tgtIn = parseInt($(this).find('.num').attr('alt'));
		dpmodal.fadeIn(350);
		dpmPanels.slick('setPosition').slick('slickGoTo', tgtIn - 1, true).hide().delay(50).fadeIn(350);
		return false;
	});

	$('.dp_modal .btn_close').on('click', function () {
		dpmodal.fadeOut(250);
	});


	// scroll triggering script
	// https://www.6666666.jp/html/20191212/
	window.addEventListener('load', function () {
		// IntersectionObserverの作成
		const observer = new IntersectionObserver(function (entries) {
			for (let i = 0; i < entries.length; i++) {
				// 領域内なら処理を実行
				if (entries[i].intersectionRatio <= 0) continue;
				showElm(entries[i].target);
			}
		}, {
			// オプション
			rootMargin: '-10% 0% -10% 0%'
		});
		// 監視対象の追加
		const elements = document.querySelectorAll('.anim');
		for (let i = 0; i < elements.length; i++) {
			observer.observe(elements[i]);
		}
		// 領域内に入ったとき実行する処理
		function showElm(e) {
			e.classList.add('triggered');
			observer.unobserve(e);
		}
	}, false);


	// アニメーション
	setInterval(function () {
		$('.js-order01').addClass('on');
		$('.js-order02').addClass('on');
		setTimeout(function () {
			$('.js-order01').removeClass('on');
			$('.js-order02').removeClass('on');
			$('.js-order03').addClass('on');
		}, 1000);
		setTimeout(function () {
			$('.js-order03').removeClass('on');
			$('.js-order04').addClass('on');
		}, 2000);
		setTimeout(function () {
			$('.js-order04').removeClass('on');
			$('.js-order05').addClass('on');
		}, 3000);
		setTimeout(function () {
			$('.js-order05').removeClass('on');
			$('.js-order06').addClass('on');
			$('.js-order07').addClass('on');
		}, 4000);
		setTimeout(function () {
			$('.js-order06').removeClass('on');
			$('.js-order07').removeClass('on');
		}, 5000);
	}, 6000);

	// EU圏かどうかの判断
	$.getJSON('https://ipapi.co/json/', function (data) {
		if (data.in_eu) {
			$("body").addClass("in_eu");
		}
	});

	//D-PICS Conceptブロックでで画像サイズを計算する
	var blockConcept = $('.block_concept'),
		bch1 = blockConcept.find('h1'),
		bch1Mb = parseInt(bch1.css('marginBottom')),
		bcPhotoMb = parseInt(blockConcept.find('.photos img').css('marginBottom'));
	$(window).on('load resize orientationchange', function () {
		var bcH = blockConcept.find('.inner').innerHeight(),
			bch1H = bch1.outerHeight();

		blockConcept.find('.img_yatai').css({
			'height': bcH - (bch1H + bch1Mb + bcPhotoMb),
		});
		blockConcept.find('.img_yatai_wrapper').css({
			'bottom': bcPhotoMb,
		});
	});

	// 検索フォームのルーペをクリックしたらformをsubmitさせる
	$('.loupe').click(function () {
		$(this).closest('form').submit();
	});

	// iframeの親要素のレスポンシブクラスを追加
	$('.single_sec iframe, .lower iframe').parent().addClass("single_sec__youtubeBox");

	//.5秒後に画面フェードイン
	$('body').addClass('loading');
	setTimeout(function () {
		$('body').removeClass('loading');
	}, 500);

	// singleページで、同じ背景色が連続する場合に相殺用のクラスを付与
	if ($('.single_sec2').length) {
		var sec2 = $('.single_sec2');
		$(sec2).each(function () {
			var sec2Bg = $(this).css('backgroundColor');
			var sibBg = $(this).prev().css('backgroundColor');
			if (sec2Bg == sibBg) {
				$(this).addClass('siblings');
			}
		});
		if ($(".mv__single").next('.single_sec2').css('backgroundColor') == "rgb(255, 255, 255)") {
			$(".mv__single").next('.single_sec2').addClass('first-negative');
		}
	};
});