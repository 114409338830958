	// scroll triggering script
// https://www.6666666.jp/html/20191212/
window.addEventListener('load', function () {
	// IntersectionObserverの作成
	const observer = new IntersectionObserver
	(function (entries) {
		for (let i = 0; i < entries.length; i++) {
			// 領域内なら処理を実行
			if (entries[i].intersectionRatio <= 0) continue;
			showElm1(entries[i].target);
			showElm2(entries[i].target);
		}
	}, {
		// オプション
		rootMargin: '-10% 0% -10% 0%'
	});

	// HOME画面：監視対象の追加
	const elements1 = document.querySelectorAll('.js-fadeUp');
	for (let i = 0; i < elements1.length; i++) {
		observer.observe(elements1[i]);
	}
	// 領域内に入ったとき実行する処理
	function showElm1(e) {
		e.classList.add('scrollin');
		observer.unobserve(e);
	}

	// D-PICS：監視対象の追加
	const elements2 = document.querySelectorAll('.anim');
	for (let i = 0; i < elements2.length; i++) {
		observer.observe(elements2[i]);
	}
	// 領域内に入ったとき実行する処理
	function showElm2(e) {
		e.classList.add('triggered');
		observer.unobserve(e);
	}
}, false);